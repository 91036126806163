<template>
  <!--
    Used to edit a collection in videopostprocessing
  -->
  <div class="collectionEdit">
    <template v-if="collection">
      <form>
        <div class="form-group">
          <h4 class="m-0 mt-3">
            {{ $t('collectionEditComponent.collection') }}
          </h4>
          <hr class="m-0 mb-3">
          <label>{{ $t('name') }}</label>
          <input
            v-model="collection.name"
            v-focus
            :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
            type="text"
          >
          <hr class="m-0 mb-3">
          <label>{{ $t('description') }}</label>
          <input
            v-model="collection.description"
            v-focus
            :class="['form-control', { 'is-invalid': $validator.errors.has('Description') }]"
            type="text"
          >
          <span
            v-show="errors.has('Description')"
            class="badge badge-danger"
          >{{ errors.first('Description') }}</span>
        </div>
        <button
          class="btn btn-sm btn-primary float-right"
          @click.prevent="updateCollection()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="check"
          />{{ $t('save') }}
        </button>
        <div class="clearfix" />
      </form>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "CollectionEdit",
  mixins: [
    errorMixin
  ],
  props: {
    collection: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: true,
    }
  },
  methods: {
    updateCollection () {
      let collectionDto = {
        'id': this.collection.id,
        'description': this.collection.description,
        'type': this.collection.type,
        'name': this.collection.name
      };
      this.axios.put('/VideoPostProcessing/UpdateImageCollection', collectionDto)
        .then(() => {
          this.$emit("reload");
          this.error_clear();
          this.$snotify.success(this.$t('collectionEditComponent.updatedSuccessfully'));
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>
